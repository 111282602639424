/* eslint-disable react/no-danger */

import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faUsers } from "@fortawesome/free-solid-svg-icons"

import Link from "../link/link"

import "./partner.css"
import Portrait from "./portrait"

const Partner = ({ markdown }) => (
  <div className="partner">
    <section>
      <header>
        <h1>Partner</h1>
        <FontAwesomeIcon icon={faUsers} size="2x" />
      </header>
      <ul className="partner__list">
        {markdown.map(
          partner =>
            !partner.node.frontmatter.isadditional && (
              <li key={partner.node.frontmatter.name}>
                <Portrait
                  image={partner.node.frontmatter.image}
                  name={partner.node.frontmatter.name}
                />
                <div>
                  <h2>{partner.node.frontmatter.name}</h2>
                  {partner.node.frontmatter.title && (
                    <h3>{partner.node.frontmatter.title}</h3>
                  )}
                  <p>
                    {partner.node.frontmatter.email && (
                      <Link
                        className="partner__list-email"
                        to={`mailto:${partner.node.frontmatter.email}`}
                      >
                        {partner.node.frontmatter.email}
                      </Link>
                    )}
                  </p>
                  <p>
                    {partner.node.frontmatter.link && (
                      <Link
                        className="partner__list-link"
                        to={`${partner.node.frontmatter.link}`}
                      >
                        {partner.node.frontmatter.link}
                      </Link>
                    )}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{ __html: partner.node.html }}
                  />
                </div>
              </li>
            )
        )}
      </ul>
    </section>
    <section>
      <header>
        <h1>Assoziierte Partner</h1>
        <FontAwesomeIcon icon={faUsers} size="2x" />
      </header>
      <ul className="partner__list">
        {markdown.map(
          partner =>
            partner.node.frontmatter.isadditional && (
              <li key={partner.node.frontmatter.name}>
                <Portrait
                  image={partner.node.frontmatter.image}
                  name={partner.node.frontmatter.name}
                />
                <div>
                  <h2>{partner.node.frontmatter.name}</h2>
                  {partner.node.frontmatter.title && (
                    <h3>{partner.node.frontmatter.title}</h3>
                  )}
                  <p>
                    {partner.node.frontmatter.email && (
                      <Link
                        className="partner__list-email"
                        to={`mailto:${partner.node.frontmatter.email}`}
                      >
                        {partner.node.frontmatter.email}
                      </Link>
                    )}
                  </p>
                  <p>
                    {partner.node.frontmatter.link && (
                      <Link
                        className="partner__list-link"
                        to={`${partner.node.frontmatter.link}`}
                      >
                        {partner.node.frontmatter.link}
                      </Link>
                    )}
                  </p>
                  <div
                    dangerouslySetInnerHTML={{ __html: partner.node.html }}
                  />
                </div>
              </li>
            )
        )}
      </ul>
    </section>
  </div>
)

Partner.propTypes = {
  markdown: PropTypes.arrayOf(
    PropTypes.shape({
      node: PropTypes.shape({
        frontmatter: PropTypes.shape({
          email: PropTypes.string,
          image: PropTypes.shape({}),
          name: PropTypes.string,
          title: PropTypes.string,
        }),
        html: PropTypes.node,
      }),
    })
  ).isRequired,
}

export default Partner
