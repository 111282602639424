import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import React from "react"

import Placeholder from "../../images/partner/placeholder.inline.svg"

const Portrait = ({ name, image }) =>
  image ? (
    <GatsbyImage
      alt={name}
      className="partner__list-image"
      image={image.childImageSharp.gatsbyImageData}
    />
  ) : (
    <div className="partner__list-image">
      <Placeholder />
    </div>
  )

Portrait.defaultProps = {
  image: null,
}

Portrait.propTypes = {
  image: PropTypes.shape({
    childImageSharp: PropTypes.shape({
      gatsbyImageData: PropTypes.shape({}),
    }),
  }),
  name: PropTypes.string.isRequired,
}

export default Portrait
