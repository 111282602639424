import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Partner from "./partner"

import sortMarkdown from "../../utils/sort-markdown"

const PartnerContainer = () => {
  const query = useStaticQuery(
    graphql`
      query {
        markdown: allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/partner/" } }
        ) {
          edges {
            node {
              html
              frontmatter {
                isadditional
                link
                image {
                  childImageSharp {
                    gatsbyImageData(
                      quality: 90
                      height: 300
                      width: 300
                      placeholder: BLURRED
                    )
                  }
                }
                name
                title
                order
              }
            }
          }
        }
      }
    `
  )

  const markdown = sortMarkdown(query.markdown.edges, ["order", "name"])

  return markdown.length > 0 ? <Partner markdown={markdown} /> : null
}

export default PartnerContainer
